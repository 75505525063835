import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateBrandRequest,
  UpdateBrandStatusRequest,
  UpdateBrandRequest,
} from '@/data/payload/api/BrandRequest'
import { BrandPresenter } from '@/app/ui/presenters/BrandPresenter'
import { Brand, Brands } from '@/domain/entities/Brand'
import { Pagination } from '@/domain/entities/Pagination'
import { BRAND_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'

export interface BrandState {
  isLoading: boolean
  paginationData: Pagination
  brandData: Brand[]
  brandDetail: Brand
}

export interface parametersInterface { 
  page?: number; 
  perPage?: number; 
  name?: string | undefined 
}

interface DataStatusUpdateInterface {
  id: number
  active: boolean
}

@Module({ namespaced: true, store, name: 'brand', dynamic: true })
class BrandController extends VuexModule implements BrandState {
  private presenter: BrandPresenter = container.resolve(BrandPresenter)
  public isLoading = false
  public paginationData = new Pagination(1, BRAND_PAGINATION)
  public brandData: Brand[] = []
  public brandDetail = new Brand()
  public statusCreateUpdateBrand = ''
  public dataStatusUpdateStatus: DataStatusUpdateInterface | null = null

  @Action({ rawError: true })
  public createBrand(form: { name: string; image: Blob }) {
    this.setLoading(true)

    this.presenter
      .create(new CreateBrandRequest(form.name, form.image))
      .then(() => {
        this.setStatusCreateUpdateBrand(EventBusConstants.CREATE_BRAND_SUCCESS)
      })
      .catch(error => {
        this.setStatusCreateUpdateBrand(EventBusConstants.CREATE_BRAND_FAILED)
        Vue.notify({
          title: 'Create Brand Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateBrand(form: { id: number; name: string; image: Blob }) {
    this.setLoading(true)

    this.presenter
      .update(form.id, new UpdateBrandRequest(form.name, form.image))
      .then(() => {
        this.setStatusCreateUpdateBrand(EventBusConstants.UPDATE_BRAND_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Brand Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateBrandStatus(form: { id: number; isActive: boolean }) {
    this.setLoading(true)

    this.presenter
      .updateStatus(form.id, new UpdateBrandStatusRequest(form.isActive))
      .then(() => {
        this.setDataStatusUpdateStatus({ 
          id: form.id, 
          active: form.isActive
        })
        this.setStatusCreateUpdateBrand(EventBusConstants.UPDATE_BRAND_STATUS_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: `${form.isActive ? 'Activate' : 'Deactive'} Brand Failed`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getBrandList(params: parametersInterface) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setBrandData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Brand Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getBrandDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setBrandDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Brand Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setBrandData(brands: Brands) {
    this.paginationData = brands.pagination as Pagination
    this.brandData = brands.data as Brand[]
  }

  @Mutation
  private setBrandDetail(brand: Brand) {
    this.brandDetail = brand
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setStatusCreateUpdateBrand(status: string) {
    this.statusCreateUpdateBrand = status
  }

  @Mutation
  public setDataStatusUpdateStatus(data: DataStatusUpdateInterface | null) {
    this.dataStatusUpdateStatus = data
  }

  @Mutation
  public updateBrandActive(form: { id: number, active: boolean }) {
    this.brandData = this.brandData.map(brand => {
      return brand.id === form.id
        ? {
            ...brand,
            active: form.active,
          }
        : brand
    })
  }
}

export default getModule(BrandController)
