import {
  CreateBrandRequestInterface,
  UpdateBrandRequestInterface,
  UpdateBrandStatusRequestInterface,
} from '@/data/payload/contracts/BrandRequest'

export class CreateBrandRequest implements CreateBrandRequestInterface {
  public name?: string
  public image?: Blob

  constructor(name?: string, image?: Blob) {
    this.name = name
    this.image = image
  }

  public toPayload() {
    const data = new FormData()
    data.append('name', this.name as string)
    data.append('image', this.image as Blob)

    return data
  }
}

export class UpdateBrandRequest implements UpdateBrandRequestInterface {
  public name?: string
  public image?: Blob

  constructor(name?: string, image?: Blob) {
    this.name = name
    this.image = image
  }

  public toPayload() {
    const data = new FormData()
    data.append('name', this.name as string)
    data.append('image', this.image as Blob)

    return data
  }
}

export class UpdateBrandStatusRequest
  implements UpdateBrandStatusRequestInterface {
  public isActive?: boolean

  constructor(isActive?: boolean) {
    this.isActive = isActive
  }

  public toPayload() {
    const data = new FormData()
    data.append('active', Number(this.isActive).toString())

    return data
  }
}
