














































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, email, maxLength, ValidationRule } from 'vuelidate/lib/validators'
import {
  phoneNumber,
  negativeDouble,
} from '@/app/infrastructures/misc/ValidationRules'
import Button from '@/app/ui/components/Button/index.vue'
import FileInput from '@/app/ui/components/FileInput/index.vue'
import merchantController from '@/app/ui/controllers/MerchantController'
import brandController from '@/app/ui/controllers/BrandController'
import routeController from '@/app/ui/controllers/RouteController'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import MerchantTextInput from '../components/MerchantTextInput/index.vue'
import MerchantDropdown from '../components/MerchantDropdown/index.vue'
import { Brand } from '@/domain/entities/Brand'

interface DropDownInterface {
  value: number | string
  label: string
}

interface Form {
  profile: {
    name: string
    brand: DropDownInterface | null
    city: DropDownInterface | null
    district: DropDownInterface | null
    description: string
    address: string
    longitude: number | null
    latitude: number | null
    banner: Blob | File | null
  }
  bank: {
    bankName: string
    accountName: string
    accountNumber: string
  }
  account: {
    name: string
    email: string
    phone: string
  }
}

interface ValidationsInterface {
  form: {
    profile: {
      name: {
        required: () => ValidationRule,
        maxLength: ValidationRule
      },
      brand: {
        required: () => ValidationRule
      },
      city: {
        required: () => ValidationRule
      },
      district: {
        required: () => ValidationRule
      },
      description: {
        required: () => ValidationRule
      },
      address: {
        required: () => ValidationRule,
        maxLength: ValidationRule
      },
      latitude: {
        required: () => ValidationRule,
        negativeDouble: ValidationRule
      },
      longitude: {
        required: () => ValidationRule,
        negativeDouble: ValidationRule
      },
      banner: {
        required: () => ValidationRule,
      },
    }
    bank: {
      bankName: {
        required: () => ValidationRule,
      },
      accountName: {
        required: () => ValidationRule,
      },
      accountNumber: {
        required: () => ValidationRule,
      }
    },
    account: {
      name: {
        required: () => ValidationRule,
      },
      email: {
        required: () => ValidationRule,
        email: () => ValidationRule,
      },
      phone: {
        required: () => ValidationRule,
        phoneNumber: ValidationRule
      }
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    MerchantTextInput,
    MerchantDropdown,
    Button,
    FileInput,
    Modal,
    LoadingOverlay,
  },
})
export default class CreateMerchant extends Vue {
  controller = merchantController
  brandController = brandController
  routeController = routeController

  form: Form = {
    profile: {
      name: '',
      brand: null,
      city: null,
      district: null,
      description: '',
      address: '',
      latitude: null,
      longitude: null,
      banner: null,
    },
    bank: {
      bankName: '',
      accountName: '',
      accountNumber: '',
    },
    account: {
      name: '',
      email: '',
      phone: '',
    },
  }
  confirmationModal = false
  successModal = false

  brandList: DropDownInterface[] = []

  created(): void {
    this.fetchBrandList()
    this.fetchCityList()
  }

  @Watch('controller.statusCreateMerchant')
  onStatusCreateMerchantChanged(status: string): void {
    if (status != '' && status === EventBusConstants.CREATE_MERCHANT_SUCCESS) {
      this.confirmationModal = false
      this.successModal = true
    }
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        profile: {
          name: {
            required,
            maxLength: maxLength(30),
          },
          brand: {
            required,
          },
          city: {
            required,
          },
          district: {
            required,
          },
          description: {
            required,
          },
          address: {
            required,
            maxLength: maxLength(100),
          },
          latitude: {
            required,
            negativeDouble,
          },
          longitude: {
            required,
            negativeDouble,
          },
          banner: {
            required,
          },
        },
        bank: {
          bankName: {
            required,
          },
          accountName: {
            required,
          },
          accountNumber: {
            required,
          },
        },
        account: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          phone: {
            required,
            phoneNumber,
          },
        },
      },
    }
  }

  private changeImage(file: File): void {
    this.form.profile.banner = file
  }

  private createMerchant(): void {
    this.controller.createMerchant(this.form)
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'MerchantListPage' })
  }

  private getBrandById(id: string): void {
    this.brandController.getBrandDetail(id)
  }

  private fetchBrandList(search?: string): void {
    if (this.$route.params.id) {
      this.getBrandById(this.$route.params.id)
    } else {
      if (search === '') search = undefined
      brandController.getBrandList({
        perPage: 30,
        name: search,
      })
    }
  }

  private brandSearch = Utils.debounce((search: string): void => {
    this.fetchBrandList(search)
  }, 500)

  private fetchCityList(): void {
    routeController.getCityList({ value: 'name-lc' })
  }

  private fetchDistrictList(city: string): void {
    routeController.getCityDistrictList({ city, options: { value: 'name' } })
  }

  @Watch('brandController.brandDetail')
  onBrandDetailChanged(val: Brand): void {
    if (val.id && val.name) {
      this.form.profile.brand = {
        value: val.id,
        label: val.name,
      }
    }
  }

  @Watch('brandController.brandData')
  onBrandDataChanged(val: Brand[]): void {
    this.brandList = val.map((brand) => {
      return {
        value: <number>brand.id,
        label: <string>brand.name,
      }
    })
  }

  get cityList(): DropDownInterface[] {
    return routeController.cityData
  }

  get districtList(): DropDownInterface[] {
    return routeController.districtData
  }

  @Watch('form.profile.city')
  onCityChanged(val: DropDownInterface | null): void {
    if (val && val.value) {
      this.form.profile.district = null
      this.fetchDistrictList(<string>val.value)
    }
  }

  @Watch('form.account.phone')
  onPhoneNumberChanged(val: string): void {
    if (val.startsWith('08') || val.startsWith('62')) {
      this.form.account.phone = Utils.countryIndonesiaPhoneNumber(val)
    }
  }

  beforeDestroy(): void {
    this.controller.setStatusCreateMerchant('')
  }
}
